import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import Service from "../extra/getAPI";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../context/AuthProvider";
import ViewDetail from "./../assets/images/viewDetail.png";
import Helper from "../extra/helper";
import { exportTableToExcel } from "./../extra/export";
import Export from "./../components/table-excel/excel-quan-ly-bao-cao";
import Itimkiem from "./../assets/images/itimkiem.png";
import Itaive from "./../assets/images/taive.png";

function ReportManagement() {
  const { profile, agency, department, Department } = useContext(AuthContext);
  const [dataOrders, seDataOrders] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [offset, setOffset] = useState(1);
  const [pageS, setPageS] = useState(0);
  const [listUser, setListUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [dates, setDates] = useState(null);
  const rolesUser = profile?.extra_info?.roles;

  const project = [
    {
      title: "Số hợp đồng",
      dataIndex: "cert_num",
    },
    {
      title: "Biển kiểm soát",
      render: (_, data) => (
        <div>{data?.license || data?.chassis || data?.engine}</div>
      ),
    },
    {
      title: "Số điện thoại",
      render: (_, data) => <div>{data?.buyer_phone}</div>,
    },
    {
      title: "Phí bảo hiểm (có VAT)",
      render: (_, data) => <div>{Helper._formatMoney(data?.fees)} VNĐ</div>,
    },
    {
      title: "Ngày thanh toán",
      render: (_, data) => (
        <div>{Helper._formatDayNoHour(data?.payment_date)}</div>
      ),
    },
    {
      title: "Tên khách hàng",
      render: (_, data) => <div>{data?.buyer_name}</div>,
    },
    {
      title: "Nhân sự upload",
      render: (_, data) => <div>{data?.user_merchant?.fullname}</div>,
    },
    {
      title: "Phòng ban / đơn vị",
      render: (_, data) => <div>{data?.department_public?.title}</div>,
    },
    {
      title: "Chi nhánh / Công ty con",
      render: (_, data) => <div>{data?.agency_public?.title}</div>,
    },
    {
      title: (
        <span style={{ background: "#fff", width: "100px" }}>Chi tiết</span>
      ),
      fixed: "right",
      align: "center",
      hidden: !rolesUser?.includes("report view"),
      render: (_, data) => (
        <a href={data?.certificate?.link} target="_blank">
          <img src={ViewDetail} style={{ width: 25 }} />
        </a>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    GetCertificate(formData);
    GetUser();
    // exportFile();
  }, [offset]);

  const GetUser = () => {
    setLoading(true);
    const data = new URLSearchParams({
      page: offset,
      limit: 99999,
    });
    Service.apicall("GET", `/api/v1/portal/user?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setListUser(res?.data?.result?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetCertificate = (values) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: offset,
      limit: 20,
      fromDate: Helper._formatDayNoHour2(values?.date?.[0]),
      toDate: Helper._formatDayNoHour2(values?.date?.[1]),
      search: values?.search || "",
      agency: values?.agency || "",
      department: values?.department || "",
      user: values?.user || "",
    });
    Service.apicall("GET", `/api/v1/portal/ocr-certificate?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          seDataOrders(res?.data?.result);
          setPageS(res?.data?.result?.total);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetDataExport = (values) => {
    setLoading(true);
    if (!values?.date) {
      message.error("Vui lòng chọn ngày xuất!");
      setLoading(false);
      return;
    }
    const data = new URLSearchParams({
      page: offset,
      limit: pageS,
      fromDate: Helper._formatDayNoHour2(values?.date?.[0]),
      toDate: Helper._formatDayNoHour2(values?.date?.[1]),
      search: values?.search || "",
      agency: values?.agency || "",
      department: values?.department || "",
      user: values?.user || "",
    });
    Service.apicall("GET", `/api/v1/portal/ocr-certificate?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDataExport(res?.data?.result?.data);
          exportFile();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        GetCertificate(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
    if (name !== "search") {
      onFinish();
    }
  };

  const exportFile = async () => {
    setTimeout(() => {
      exportTableToExcel("report", `Quan-ly-bao-cao.xlsx`);
    }, 1000);
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "months") > 0;
    const tooEarly = dates[1] && dates[1].diff(current, "months") > 0;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #d2d2d2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>Quản lý báo cáo</div>
      <div style={{ background: "#fff", padding: 10, borderRadius: 10 }}>
        <Export data={dataExport} />
        <div style={{ fontSize: 28, color: "#007185", fontWeight: 600 }}>
          Báo cáo
        </div>
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          style={{ fontSize: 14, width: "100%", marginBottom: 10 }}
          scrollToFirstError
        >
          <Row gutter={[10, 0]}>
            <Col xs={24}>
              <Form.Item
                name="search"
                rules={[
                  {
                    required: false,
                    message: "Vui lòng nhập đầy đủ thông tin.",
                  },
                ]}
                style={{ ...css.FormItemsHolder }}
              >
                <Input
                  allowClear
                  onChange={(e) =>
                    onChangForm({ data: e.target.value, name: "search" })
                  }
                  className="custom-input"
                  prefix={
                    <img
                      src={Itimkiem}
                      alt="Global Care icon"
                      width={14}
                      height={14}
                    />
                  }
                  suffix={
                    <Button
                      onClick={() => onFinish()}
                      style={{
                        background: "#007185",
                        borderRadius: 4,
                        color: "#fff",
                        padding: "4px 10px",
                        fontWeight: 500,
                        cursor: "pointer",
                        height: 30,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      Tìm kiếm
                    </Button>
                  }
                  style={{
                    ...css.input,
                    maxWidth: 375,
                    fontSize: 12,
                  }}
                  placeholder="Nhập thông tin tìm kiếm..."
                />
              </Form.Item>
            </Col>
            {+profile?.merchant_level_id === 1 && (
              <Col xl={5} xs={24}>
                <Form.Item
                  name="agency"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                  style={css.FormItemsHolder}
                >
                  <Select
                    allowClear={true}
                    className="custom-select"
                    placeholder={
                      <span style={{ color: "#343A40" }}>
                        Chi nhánh / Công ty con
                      </span>
                    }
                    onChange={(e) => {
                      onChangForm({ data: e, name: "agency" });
                      Department(e);
                    }}
                  >
                    {agency?.map((item, index) => (
                      <Option key={index} value={item?.uid}>
                        {item?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col xl={5} xs={24}>
              <Form.Item
                name="department"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder }}
              >
                <Select
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Phòng ban / đơn vị</span>
                  }
                  onChange={(e) => {
                    onChangForm({ data: e, name: "department" });
                  }}
                >
                  {department?.map((item, index) => (
                    <Option key={index} value={item?.uid}>
                      {item?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={5} xs={24}>
              <Form.Item
                name="user"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={css.FormItemsHolder}
              >
                <Select
                  onChange={(e) => {
                    onChangForm({ data: e, name: "user" });
                  }}
                  allowClear={true}
                  className="custom-select"
                  placeholder={
                    <span style={{ color: "#343A40" }}>Nhân sự upload</span>
                  }
                >
                  {listUser?.map((item, index) => (
                    <Option key={index} value={item?.uid}>
                      {item?.fullname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6} xs={24}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
                style={{ ...css.FormItemsHolder }}
              >
                <RangePicker
                  style={{
                    width: "100%",
                    fontSize: 12,
                    borderRadius: 4,
                    border: "1px solid #D2D2D2",
                  }}
                  onOpenChange={onOpenChange}
                  onChange={(e) => {
                    onChangForm({ data: e, name: "date" });
                  }}
                  format={"DD/MM/YYYY"}
                  placeholder={["Từ ngày", "Đến ngày"]}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => setDates(val)}
                  // showTime
                />
              </Form.Item>
            </Col>
            <Col xl={+profile?.merchant_level_id === 1 ? 3 : 8} xs={0}>
              <div
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  marginTop: 5,
                }}
              >
                {rolesUser?.includes("report report") && (
                  <Button
                    disabled={loading}
                    onClick={() => GetDataExport(formData)}
                    style={{ ...css.button, fontWeight: "bold" }}
                  >
                    <img src={Itaive} style={{ marginRight: 5 }} />
                    Xuất báo cáo
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Form>
        <div className="tabled2ß">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Table
                loading={loading}
                columns={project}
                dataSource={dataOrders?.data}
                pagination={false}
                className="ant-border-space baocao"
                rowClassName={() => "rowClassName2"}
                bordered={false}
                scroll={{ x: "auto" }}
                showHeader={true}
              />
              <div style={{ textAlign: "right", margin: "10px" }}>
                <Pagination
                  className="paginateCustom2"
                  total={pageS || 20}
                  pageSize={20}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} trên tổng ${total} đơn`
                  }
                  defaultCurrent={1}
                  defaultPageSize={0}
                  // className="paginateCustom"
                  onChange={(e) => {
                    setOffset(e);
                  }}
                  size="small"
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Button
        style={{
          background: "#ffffff",
          color: "#007185",
          width: "90px",
          textAlign: "center",
          borderRadius: 9,
          border: "1px solid #007185",
          fontWeight: "500",
          height: 40,
          margin: '10px 0px',
        }}
      >
        {/* <Link to={""}> */}
        <b>Quay lại</b>
        {/* </Link> */}
      </Button>
    </>
  );
}

export default ReportManagement;

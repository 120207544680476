import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import History from "../routes/history";
import Service from "./../extra/getAPI";
import Helper from "../extra/helper";
import MdConfirmAndDone from "./../components/modal/mdConfirmAndDone";
import MdWarning from "./../components/modal/mdWarningOCR";
import { AuthContext } from "./../context/AuthProvider";

import {
  Button,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Checkbox,
  message,
} from "antd";

const OCR = () => {
  const { dataOCR, file } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [sendDone, setSendDone] = useState(false);
  const [messWarning, setMessWarning] = useState("");

  const [form] = Form.useForm();
  const formRef = React.createRef();

  useEffect(() => {
    form.setFieldsValue({
      cert_num: dataOCR?.result?.no,
      buyer_name: dataOCR?.result?.owner_name,
      buyer_phone: dataOCR?.result?.owner_phone,
      buyer_address: dataOCR?.result?.owner_address,
      license: dataOCR?.result?.vehicle_plate_number,
      chassis: dataOCR?.result?.vehicle_chassis_number,
      engine: dataOCR?.result?.vehicle_engine_number,
      vehicle_type: dataOCR?.result?.vehicle_type,
      vehicle_capacity: dataOCR?.result?.vehicle_capacity,
      vehicle_seats: dataOCR?.result?.vehicle_seats?.replace(/[^0-9]/g, ""),
      usage_type: dataOCR?.result?.usage_type,
      num_drivers: dataOCR?.result?.num_drivers?.replace(/[^0-9]/g, ""),
      num_passengers: dataOCR?.result?.num_passengers?.replace(/[^0-9]/g, ""),
      total_people: dataOCR?.result?.total_people?.replace(/[^0-9]/g, ""),
      insurance_benefit: dataOCR?.result?.insurance_benefit?.replace(
        /[^0-9]/g,
        ""
      ),
      insurance_cost: dataOCR?.result?.insurance_cost?.replace(/[^0-9]/g, ""),
      cost: dataOCR?.result?.cost?.replace(/[^0-9]/g, ""),
      cost_vat: dataOCR?.result?.cost_vat?.replace(/[^0-9]/g, ""),
      beginDateHour: Helper._getDuration(dataOCR?.result?.valid_from)?.hour,
      beginDateMinute: Helper._getDuration(dataOCR?.result?.valid_from)?.minute,
      beginDate: Helper._getDuration(dataOCR?.result?.valid_from)?.day,
      beginDateMonth: Helper._getDuration(dataOCR?.result?.valid_from)?.month,
      beginDateYear: Helper._getDuration(dataOCR?.result?.valid_from)?.year,
      arriveDateHour: Helper._getDuration(dataOCR?.result?.valid_to)?.hour,
      arriveDateMinute: Helper._getDuration(dataOCR?.result?.valid_to)?.minute,
      arriveDateDays: Helper._getDuration(dataOCR?.result?.valid_to)?.day,
      arriveDateMonths: Helper._getDuration(dataOCR?.result?.valid_to)?.month,
      arriveDateYear: Helper._getDuration(dataOCR?.result?.valid_to)?.year,
      // registered_houre: Helper._getDuration('08:00 ' + dataOCR?.result?.registered_at)?.hour,
      // registered_minute: Helper._getDuration('08:00 ' + dataOCR?.result?.registered_at)?.minute,
      registered_days: Helper._getDuration(
        "08:00 " + dataOCR?.result?.registered_at
      )?.day,
      registered_month: Helper._getDuration(
        "08:00 " + dataOCR?.result?.registered_at
      )?.month,
      registered_year: Helper._getDuration(
        "08:00 " + dataOCR?.result?.registered_at
      )?.year,
      paymentTermDays: Helper._getDuration(
        "08:00 " + dataOCR?.result?.cost_duration
      )?.day,
      paymentTermMonths: Helper._getDuration(
        "08:00 " + dataOCR?.result?.cost_duration
      )?.month,
      paymentTermYear: Helper._getDuration(
        "08:00 " + dataOCR?.result?.cost_duration
      )?.year,
    });
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        if (+values?.cost_vat < +values?.cost) {
          message.error("Phí bảo hiểm có thể bị sai. Vui lòng kiểm tra lại!");
          return;
        }
        if (
          +values?.beginDateHour > 23 ||
          +values?.arriveDateHour > 23 ||
          +values?.arriveDateMinute > 59 ||
          +values?.beginDateMinute > 59
        ) {
          message.error("Thời hạn bảo hiểm chưa đúng. Vui lòng kiểm tra lại!");
          return;
        }
        SendRecords(values);
      })
      .catch((err) => {
        message.error("Một số thông tin chưa đúng. Vui lòng kiểm tra lại!");
        console.error("err:", err);
      });
  };

  const SendRecords = (values) => {
    setLoading(true);
    const data = {
      certificate: {
        link: dataOCR?.file,
        type: file?.type === "application/pdf" ? "pdf" : "image",
      },
      cert_num: values?.cert_num,
      license: values?.license,
      chassis: values?.chassis,
      engine: values?.engine,
      fees: +values?.cost_vat,
      buyer_name: values?.buyer_name,
      buyer_phone: values?.buyer_phone,
      buyer_address: values?.buyer_address,
      payment_date:
        values?.paymentTermDays &&
        values?.paymentTermMonths &&
        values?.paymentTermDays
          ? values?.paymentTermYear +
            "-" +
            values?.paymentTermMonths +
            "-" +
            values?.paymentTermDays
          : null,
      fee: +values?.cost,
      accidentsCoDriver: +values?.num_drivers || 0,
      accidentsPassengers: +values?.num_drivers || 0,
      seats: +values?.vehicle_seats,
      accidentsCoDriverPassengersFee: +values?.insurance_benefit || 0,
      useType: values?.usage_type,
      startedAt:
        values?.beginDate +
        "-" +
        values?.beginDateMonth +
        "-" +
        values?.beginDateYear +
        " " +
        values?.beginDateHour +
        ":" +
        values?.beginDateMinute,
      endedAt:
        values?.arriveDateDays +
        "-" +
        values?.arriveDateMonths +
        "-" +
        values?.arriveDateYear +
        " " +
        values?.arriveDateHour +
        ":" +
        values?.arriveDateMinute,
      ocr_data: dataOCR?.result
    };
    Service.apicall2("POST", `/api/v1/portal/ocr-certificate`, data)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          setSendDone(true);
          setOpen(true);
          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenWarning(true);
        setMessWarning(error.response.data.message);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    setOpenWarning(false);
  };

  const css = {
    input: {
      height: 30,
      width: 160,
      color: "red",
      borderRadius: 0,
      textAlign: "center",
      padding: 0,
    },
    input2: {
      height: 25,
      width: 25,
      borderRadius: 0,
      textAlign: "center",
      padding: 0,
      fontSize: 12,
      color: "black",
      fontWeight: 400,
    },
    divForm: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    btn: {
      background: "#ffffff",
      color: "#007185",
      textAlign: "center",
      borderRadius: 4,
      border: "1px solid #007185",
      fontWeight: "500",
      height: 40,
    },
  };

  return (
    <>
      <MdConfirmAndDone
        onFinish={onFinish}
        done={sendDone}
        open={open}
        handleCancel={handleCancel}
      />
      <MdWarning
        messWarning={messWarning}
        open={openWarning}
        handleCancel={handleCancel}
      />

      <Form
        layout="horizontal"
        ref={formRef}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError
      >
        <div style={{ marginBottom: 10 }}>
          <span style={{ color: "#969696" }}>Upload chứng từ</span>{" "}
          <span style={{ color: "#343A40" }}>/ OCR</span>
        </div>
        <Row
          style={{
            borderRadius: 16,
            fontSize: 12,
            maxWidth: 1600,
          }}
        >
          <Col
            style={{
              border: "0.86px solid #000000",
              padding: 17,
              background: "#F0FBF8",
            }}
            xs={24}
            md={24}
            lg={8}
          >
            <div
              style={{
                border: "0.86px solid #000000",
                padding: 17,
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div style={{ fontWeight: 500, marginRight: 5 }}>Số:</div>
                <Form.Item
                  name="cert_num"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  style={{ padding: 0, margin: 0 }}
                >
                  <Input
                    style={{
                      ...css.input,
                      fontSize: 17,
                      textTransform: "uppercase",
                    }}
                    placeholder=""
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  fontSize: 17,
                  color: "red",
                  fontWeight: 600,
                  textAlign: "center",
                  padding: "10px 0px",
                }}
              >
                BẢO HIỂM TỰ NGUYỆN
              </div>
              <div style={{ textAlign: "justify", fontWeight: 500 }}>
                <i>
                  Giấy chứng nhận bảo hiểm (GCNBH) này được áp dụng theo các
                  điều khoản được quy định tại Quy tắc Bảo hiểm kết hợp xe cơ
                  giới hiện hành của Tổng công ty Cổ phần Bảo hiểm Hàng không và
                  là bộ phận không thể tách rời của GCNBH bắt buộc của chủ xe ô
                  tô, Chủ xe, lái xe cần đọc kỹ Quy tắc, điều khoản để được biết
                  quyền lợi và nghĩa vụ của mình khi tham gia bảo hiểm. Vui lòng
                  tham khảo trên website: www.bhhk.com.vn
                </i>
              </div>
              <div
                style={{
                  fontSize: 17,
                  color: "red",
                  fontWeight: 600,
                  marginTop: 10,
                }}
              >
                BẢO HIỂM TAI NẠN LÁI XE VÀ NGƯỜI NGỒI TRÊN XE:
              </div>
              <div style={css.divForm}>
                <div style={{ fontWeight: 500, marginRight: 5 }}>Lái xe:</div>
                <Form.Item
                  name="num_drivers"
                  rules={[
                    {
                      required: false,
                      message: (
                        <div style={{ fontSize: 10 }}>
                          Vui lòng nhập thông tin
                        </div>
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                      ),
                    },
                  ]}
                  style={{ padding: 0, margin: 0, width: "100%" }}
                >
                  <Input
                    style={{ ...css.input2, width: "100%" }}
                    placeholder=""
                  />
                </Form.Item>
                <div style={{ fontWeight: 500, marginLeft: 5 }}>người</div>
              </div>
              <div style={css.divForm}>
                <div style={{ fontWeight: 500, marginRight: 5 }}>
                  Người ngồi trên xe:
                </div>
                <Form.Item
                  name="num_passengers"
                  rules={[
                    {
                      required: false,
                      message: (
                        <div style={{ fontSize: 10 }}>
                          Vui lòng nhập thông tin
                        </div>
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                      ),
                    },
                  ]}
                  style={{ padding: 0, margin: 0, width: "100%" }}
                >
                  <Input
                    style={{ ...css.input2, width: "100%" }}
                    placeholder=""
                  />
                </Form.Item>
                <div style={{ fontWeight: 500, marginLeft: 5 }}>người</div>
              </div>
              <div style={css.divForm}>
                <div style={{ fontWeight: 500, marginRight: 5 }}>
                  Tổng số người được bảo hiểm:
                </div>
                <Form.Item
                  name="total_people"
                  rules={[
                    {
                      required: false,
                      message: (
                        <div style={{ fontSize: 10 }}>
                          Vui lòng nhập thông tin
                        </div>
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                      ),
                    },
                  ]}
                  style={{ padding: 0, margin: 0, width: "100%" }}
                >
                  <Input
                    style={{ ...css.input2, width: "100%" }}
                    placeholder=""
                  />
                </Form.Item>
                <div style={{ fontWeight: 500, marginLeft: 5 }}>người</div>
              </div>
              <div style={css.divForm}>
                <div style={{ fontWeight: 500, marginRight: 5 }}>
                  Mức TN bảo hiểm:
                </div>
                <Form.Item
                  name="insurance_benefit"
                  rules={[
                    {
                      required: false,
                      message: (
                        <div style={{ fontSize: 10 }}>
                          Vui lòng nhập thông tin
                        </div>
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                      ),
                    },
                  ]}
                  style={{ padding: 0, margin: 0, width: "100%" }}
                >
                  <Input
                    style={{ ...css.input2, width: "100%" }}
                    placeholder=""
                  />
                </Form.Item>
                <div style={{ fontWeight: 500, marginLeft: 5 }}>
                  triệu đồng/người/vụ
                </div>
              </div>
              <div style={css.divForm}>
                <div style={{ fontWeight: 500, marginRight: 5 }}>
                  Phí bảo hiểm:
                </div>
                <Form.Item
                  name="insurance_cost"
                  rules={[
                    {
                      required: false,
                      message: (
                        <div style={{ fontSize: 10 }}>
                          Vui lòng nhập thông tin
                        </div>
                      ),
                    },
                    {
                      pattern: /^[0-9]*$/,
                      message: (
                        <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                      ),
                    },
                  ]}
                  style={{ padding: 0, margin: 0, width: "100%" }}
                >
                  <InputNumber
                    onChange={(e) => {}}
                    min={1000}
                    className="customInputNumper"
                    maxLength={15}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }
                    parser={(value) => value.replace(/(\.*)/g, "")}
                    style={{ minWidth: "100%", ...css.input2 }}
                  />
                </Form.Item>
                <div style={{ fontWeight: 500, marginLeft: 5 }}>đồng</div>
              </div>
              <div
                style={{
                  fontSize: 17,
                  color: "red",
                  fontWeight: 500,
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <div>BÊN MUA BẢO HIỂM</div>
                  <div style={{ fontSize: 12, marginBottom: 30 }}>
                    <i>(Ký, ghi rõ họ tên)</i>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            style={{
              border: "0.86px solid #000000",
              padding: 17,
              background: "#FFE500",
            }}
            xs={24}
            md={24}
            lg={16}
          >
            <Row gutter={[17, 17]} style={{ height: "100%" }}>
              <Col xs={24} md={24} lg={11}>
                <div
                  style={{
                    border: "0.86px solid #000000",
                    padding: 17,
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ fontWeight: 500, marginRight: 5 }}>Số:</div>
                    <Form.Item
                      name="cert_num"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Input
                        style={{
                          ...css.input,
                          fontSize: 17,
                          textTransform: "uppercase",
                        }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      TÊN CHỦ XE:
                    </div>
                    <Form.Item
                      name="buyer_name"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      ĐỊA CHỈ:
                    </div>
                    <Form.Item
                      name="buyer_address"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input.TextArea
                        style={{
                          height: 10,
                          width: "100%",
                          borderRadius: 0,
                          color: "black",
                          fontSize: 12,
                          fontWeight: 400,
                        }}
                        placeholder=""
                        autoSize={{
                          minRows: 2,
                          maxRows: 2,
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      SỐ ĐIỆN THOẠI:
                    </div>
                    <Form.Item
                      name="buyer_phone"
                      rules={[
                        {
                          required: true,
                          message: (
                            <div style={{ fontSize: 10 }}>
                              Vui lòng nhập thông tin
                            </div>
                          ),
                        },
                        {
                          message: (
                            <div style={{ fontSize: 10 }}>
                              SĐT không hợp lệ.
                            </div>
                          ),
                          pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      BIỂN KIỂM SOÁT:
                    </div>
                    <Form.Item
                      name="license"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      SỐ KHUNG:
                    </div>
                    <Form.Item
                      name="chassis"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      SỐ MÁY:
                    </div>
                    <Form.Item
                      name="engine"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      LOẠI XE:
                    </div>
                    <Form.Item
                      name="vehicle_type"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      TRỌNG TẢI:
                    </div>
                    <Form.Item
                      name="vehicle_capacity"
                      rules={[
                        {
                          required: false,
                          message: (
                            <div style={{ fontSize: 10 }}>
                              Vui lòng nhập thông tin
                            </div>
                          ),
                        },
                        {
                          pattern: /^[0-9,.]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                    <div style={{ fontWeight: 500, marginLeft: 5 }}>tấn</div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      SỐ CHỖ NGỒI:
                    </div>
                    <Form.Item
                      name="vehicle_seats"
                      rules={[
                        {
                          required: true,
                          message: (
                            <div style={{ fontSize: 10 }}>
                              Vui lòng nhập thông tin
                            </div>
                          ),
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Input
                        style={{ ...css.input2, width: "100%" }}
                        placeholder=""
                      />
                    </Form.Item>
                    <div style={{ fontWeight: 500, marginLeft: 5 }}>chỗ</div>
                  </div>
                  <div style={{ marginTop: 10, fontWeight: 500 }}>
                    MỤC ĐÍCH SỬ DỤNG:
                  </div>
                  <div style={css.divForm}>
                    <Form.Item
                      name="usage_type"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng chọn thông tin",
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Row>
                          <Col xs={12}>
                            <div>
                              <span>Kinh doanh: </span>
                              <Checkbox
                                onClick={() =>
                                  setTimeout(() => {
                                    form.setFieldsValue({
                                      usage_type: ["Kinh doanh"],
                                    });
                                  }, 200)
                                }
                                value="Kinh doanh"
                              ></Checkbox>
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div>
                              <span>Không kinh doanh: </span>
                              <Checkbox
                                onClick={() =>
                                  setTimeout(() => {
                                    form.setFieldsValue({
                                      usage_type: ["Không kinh doanh"],
                                    });
                                  }, 200)
                                }
                                value="Không kinh doanh"
                              ></Checkbox>
                            </div>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </div>
                  <div style={{ marginTop: 10, fontWeight: 500 }}>
                    <div style={{ color: "red", fontWeight: 600 }}>
                      1. MỨC TNDS ĐỐI VỚI NGƯỜI THỨ BA
                    </div>
                    <div>- Về người: 150 triệu đồng/1 người/1 vụ</div>
                    <div>- Về tài sản: 100 triệu đồng/1 vụ</div>
                  </div>
                  <div style={{ marginTop: 10, fontWeight: 500 }}>
                    <div style={{ color: "red", fontWeight: 600 }}>
                      2. MỨC TNDS ĐỐI VỚI HÀNH KHÁCH
                    </div>
                    <div>- Về người: 150 triệu đồng/1 người/1 vụ</div>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={24} lg={13}>
                <div
                  style={{
                    border: "0.86px solid #000000",
                    padding: 17,
                    height: "100%",
                  }}
                >
                  <div style={{ fontWeight: 500 }}>
                    <div style={{ color: "red", fontWeight: 600 }}>
                      3.THỜI HẠN BẢO HIỂM
                    </div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 13 }}>
                      - Từ:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Form.Item
                        name="beginDateHour"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        giờ
                      </div>
                      <Form.Item
                        name="beginDateMinute"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                    </div>
                    <div style={css.divForm}>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        ngày
                      </div>
                      <Form.Item
                        name="beginDate"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        tháng
                      </div>
                      <Form.Item
                        name="beginDateMonth"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        năm
                      </div>
                      <Form.Item
                        name="beginDateYear"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input
                          style={{ ...css.input2, width: 50 }}
                          placeholder=""
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      - Đến:
                    </div>
                    <div style={css.divForm}>
                      <Form.Item
                        name="arriveDateHour"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        giờ
                      </div>
                      <Form.Item
                        name="arriveDateMinute"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                    </div>
                    <div style={css.divForm}>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        ngày
                      </div>
                      <Form.Item
                        name="arriveDateDays"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        tháng
                      </div>
                      <Form.Item
                        name="arriveDateMonths"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input style={css.input2} placeholder="" />
                      </Form.Item>
                      <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                        năm
                      </div>
                      <Form.Item
                        name="arriveDateYear"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: <div style={{ fontSize: 10 }}></div>,
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                      >
                        <Input
                          style={{ ...css.input2, width: 50 }}
                          placeholder=""
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ fontWeight: 500, marginTop: 10 }}>
                    <div style={{ color: "red", fontWeight: 600 }}>
                      4. PHÍ BẢO HIỂM
                    </div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      - Phí bảo hiểm:
                    </div>
                    <Form.Item
                      name="cost"
                      rules={[
                        {
                          required: true,
                          message: (
                            <div style={{ fontSize: 10 }}>
                              Vui lòng nhập thông tin
                            </div>
                          ),
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <InputNumber
                        onChange={(e) => {}}
                        // min={10}
                        // max={100000000000}
                        maxLength={15}
                        className="customInputNumper"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }
                        parser={(value) => value.replace(/(\.*)/g, "")}
                        style={{ minWidth: "100%", ...css.input2 }}
                      />
                    </Form.Item>
                    <div style={{ fontWeight: 500, marginLeft: 5 }}>đồng</div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      - Phí bảo hiểm (gồm VAT):
                    </div>
                    <Form.Item
                      name="cost_vat"
                      rules={[
                        {
                          required: true,
                          message: (
                            <div style={{ fontSize: 10 }}>
                              Vui lòng nhập thông tin
                            </div>
                          ),
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0, width: "100%" }}
                    >
                      <InputNumber
                        onChange={(e) => {}}
                        // min={10}
                        // max={100000000000}
                        className="customInputNumper"
                        maxLength={15}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }
                        parser={(value) => value.replace(/(\.*)/g, "")}
                        style={{ minWidth: "100%", ...css.input2 }}
                      />
                    </Form.Item>
                    <div style={{ fontWeight: 500, marginLeft: 5 }}>đồng</div>
                  </div>
                  <div style={css.divForm}>
                    <div style={{ fontWeight: 500, marginRight: 5 }}>
                      - Thời hạn thanh toán phí:
                    </div>
                    <Form.Item
                      name="paymentTermDays"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Input style={css.input2} placeholder="" />
                    </Form.Item>
                    <div style={{ fontWeight: 500, margin: "0px 2px" }}>/</div>
                    <Form.Item
                      name="paymentTermMonths"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Input style={css.input2} placeholder="" />
                    </Form.Item>
                    <div style={{ fontWeight: 500, margin: "0px 2px" }}>/</div>
                    <Form.Item
                      name="paymentTermYear"
                      rules={[
                        {
                          required: false,
                          message: "",
                        },
                        {
                          pattern: /^[0-9]*$/,
                          message: (
                            <div style={{ fontSize: 10 }}>Chỉ được nhập số</div>
                          ),
                        },
                      ]}
                      style={{ padding: 0, margin: 0 }}
                    >
                      <Input
                        style={{ ...css.input2, width: "47px" }}
                        placeholder=""
                      />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      fontSize: 17,
                      color: "red",
                      fontWeight: 500,
                      marginTop: 10,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <div>5. NGƯỜI CẤP</div>
                      <div
                        style={{
                          fontSize: 12,
                          marginBottom: 30,
                          color: "#343A40",
                        }}
                      >
                        <i>(Ký, ghi rõ họ tên)</i>
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div>BÊN MUA BẢO HIỂM</div>
                      <div
                        style={{
                          fontSize: 12,
                          marginBottom: 30,
                          color: "#343A40",
                        }}
                      >
                        <i>(Ký, ghi rõ họ tên)</i>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div
                      style={{
                        ...css.divForm,
                        fontSize: 11,
                      }}
                    >
                      <div style={{ fontWeight: 500, marginRight: 5 }}>
                        Cấp hồi
                      </div>
                      <div style={css.divForm}>
                        <Form.Item
                          name="registered_houre"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                          style={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Input
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 0,
                              textAlign: "center",
                              padding: 0,
                              fontSize: 11,
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                        <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                          giờ
                        </div>
                        <Form.Item
                          name="registered_minute"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                          style={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Input
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 0,
                              textAlign: "center",
                              padding: 0,
                              fontSize: 11,
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                      </div>
                      <div style={css.divForm}>
                        <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                          , ngày
                        </div>
                        <Form.Item
                          name="registered_days"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                          style={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Input
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 0,
                              textAlign: "center",
                              padding: 0,
                              fontSize: 11,
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                        <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                          tháng
                        </div>
                        <Form.Item
                          name="registered_month"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                          style={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Input
                            style={{
                              height: 20,
                              width: 20,
                              borderRadius: 0,
                              textAlign: "center",
                              padding: 0,
                              fontSize: 11,
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                        <div style={{ fontWeight: 500, margin: "0px 2px" }}>
                          năm
                        </div>
                        <Form.Item
                          name="registered_year"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                          style={{ padding: 0, margin: 0, width: "100%" }}
                        >
                          <Input
                            style={{
                              height: 20,
                              width: 40,
                              borderRadius: 0,
                              textAlign: "center",
                              padding: 0,
                              fontSize: 11,
                            }}
                            placeholder=""
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div style={{ textAlign: "right", fontSize: 11 }}>
                    <div style={{ fontWeight: 500 }}>
                      TỔNG CÔNG TY CỔ PHẦN BẢO HIỂM HÀNG KHÔNG
                    </div>
                    <div>
                      <i>
                        (Đại diện bên cấp ký , ghi rõ họ tên, đóng dấu của nơi
                        cấp)
                      </i>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: 11,
                      fontWeight: 500,
                      color: "red",
                      marginTop: "50%",
                    }}
                  >
                    <div>Lưu ý:</div>
                    <div style={{ marginLeft: 5 }}>
                      Giấy CNBH này chỉ có hiệu lực
                      <br /> nếu được cấp trước ngày:
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ maxWidth: 1600, marginBottom: 20 }}>
          <Row>
            <Col xs={2} xl={0}></Col>
            <Col xs={20} xl={24} style={{ width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 20,
                  width: "100%",
                }}
              >
                <Button style={css.btn}>
                  <Link to={"/upload-chung-tu/xem-hinh"}>
                    <b>Quay lại</b>
                  </Link>
                </Button>
                <Button
                  loading={loading}
                  // htmlType="submit"
                  onClick={() => setOpen(true)}
                  style={{
                    ...css.btn,
                    background: "#007185",
                    color: "#ffffff",
                  }}
                >
                  <b>Gửi hồ sơ</b>
                </Button>
              </div>
            </Col>
            <Col xs={2} xl={0}>
              {" "}
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default OCR;

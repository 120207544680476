import React, { useEffect, useState, useRef, useContext } from "react";
import { Button } from "antd";
import Upanh from "../../assets/images/upanh.png";
import Upfile from "../../assets/images/upfile.png";
import History from "./../../routes/history";
import { AuthContext } from "./../../context/AuthProvider";

function Content(props) {
  const { setFile, setDataOCR } = useContext(AuthContext);
  const [accept, setAccept] = useState(null);
  const importInputRef = useRef();

  const openAciton = () => {
    if (props?.openSigin) {
      props?.openSigin(true);
      return;
    }
    setTimeout(() => {
      importInputRef.current.click();
    }, 100);
  };

  const handleImportFile = (files) => {
    if (files && files[0]) {
      setFile(files[0]);
      setDataOCR(null);
      History.push("/upload-chung-tu/xem-hinh");
    }
  };

  return (
    <>
      <div style={{ fontSize: props.size[0], fontWeight: 600 }}>
        OCR Giấy Chứng Nhận Bảo Hiểm
      </div>
      <div style={{ fontSize: props.size[1], marginTop: "3%" }}>
        Tải lên hình ảnh hoặc file giấy chứng nhận
        <br />
        bảo hiểm để tiến hành OCR kiểm tra thông tin.
      </div>
      <div style={{ marginTop: "5%" }}>
        <Button
          style={{
            borderRadius: 56,
            color: "#007185",
            background: "#ffffff",
            fontSize: props.size[2],
            height: 78,
            width: 277,
            boxShadow: "0px 25px 35px 0px #00000040",
            // border:'1px solid #007185'
          }}
          onClick={() => {
            openAciton();
            setAccept("image/*");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Upanh}
              style={{ width: 29, marginRight: 5, height: 28 }}
            />{" "}
            Tải hình ảnh lên
          </div>
        </Button>
      </div>
      <div style={{ marginTop: "2%", fontSize: props.size[3] }}>Hoặc</div>
      <div style={{ marginTop: "2%" }}>
        <Button
          style={{
            borderRadius: 56,
            color: "#007185",
            background: "#ffffff ",
            fontSize: props.size[2],
            height: 78,
            width: 277,
            boxShadow: "0px 25px 35px 0px #00000040",
            // border:'1px solid #007185'
          }}
          onClick={() => {
            openAciton();
            setAccept("application/pdf");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={Upfile}
              style={{ width: 29, marginRight: 5, height: 28 }}
            />{" "}
            Tải file PDF lên
          </div>
        </Button>
      </div>
      <input
        ref={importInputRef}
        //capture="camera"
        type="file"
        accept={accept}
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => {
          handleImportFile(e.target.files);
        }}
        style={{ display: "none" }}
      />
    </>
  );
}

export default Content;

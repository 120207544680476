import React from "react";
import Helper from './../../extra/helper'

function ExportProviderTable(props) {

  return (
    <div style={{ display: "none" }}>
      <table
        className="collapse-border"
        id={"user"}
        data-cols-width="10, 30, 30, 40, 20, 20, 20, 20,40"
        border={"1px solid red"}
      >
        <thead style={{ fontSize: "13" }}>
          <tr>
            <th
              // data-b-a-c="FFFFFFFF"
              colSpan={9}
              data-f-name={"Times new roman"}
              data-f-sz={"24"}
              data-a-wrap={true}
              data-a-h={"center"}
              // data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Danh sách tài khoản
            </th>
          </tr>
          <tr>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              STT
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên User
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Phòng ban / đơn vị
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Chi nhánh / Công ty con
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Tên công ty
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Email
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Số điện thoại
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Ngày tạo
            </th>
            <th
              data-f-name={"Times new roman"}
              data-f-sz={"16"}
              data-b-a-s={"thin"}
              data-f-bold={true}
            >
              Trạng thái
            </th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "13" }}>
          {(props?.data || [])?.map((item, index) => {
            return (
              <tr key={index} style={{ fontSize: "13" }}>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                  data-t="n"
                >
                  {index + 1}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.fullname}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.department_public?.title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.agency_public?.title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.merchant?.title}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.email}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {item?.phone_number}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {Helper._formatDayNoHour(item?.created_date)}
                </td>
                <td
                  data-f-name={"Times new roman"}
                  data-f-sz={"16"}
                  data-b-a-s={"thin"}
                >
                  {+item?.status === 1 ? "Hoạt động" : "Không hoạt động"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(ExportProviderTable);

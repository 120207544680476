import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, Checkbox } from "antd";
import Service from "../../extra/getAPI";
import history from "../../routes/history";
import MdForgotPass from "./mdForgotPass";
import { AuthContext } from "./../../context/AuthProvider";

function Sigin(props) {
  const { GetProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);

  const handleCancel = () => {
    setOpenForgot(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    SignIn(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const SignIn = (values) => {
    setLoading(true);
    const data = {
      username: values.username,
      password: values.password,
    };
    Service.apicall("POST", `/api/v1/portal/login`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          localStorage.setItem("login", "done");
          localStorage.setItem("dataUser", JSON.stringify(res.data.result));
          GetProfile();
          setTimeout(() => {
            setLoading(false);
            history.push("/quan-ly-tai-khoan");
            // getProfile();
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <MdForgotPass open={openForgot} handleCancel={handleCancel} />
      <Modal
        footer={false}
        className="modal-custom"
        visible={props.open}
        centered={true}
        closable={false}
        onCancel={props.handleCancel}
        style={{ borderRadius: 10 }}
      >
        <div style={{ textAlign: "center", fontSize: 24, color: "#007185" }}>
          <b>Đăng Nhập</b>
        </div>
        <div>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Form.Item
              className="username"
              label={<span style={{ color: "#007185" }}>Email</span>}
              name="username"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
                {
                  message: "Vui lòng nhập đúng email.",
                  pattern: /\S+@\S+\.\S+/,
                },
              ]}
            >
              <Input placeholder="Nhập email của bạn" />
            </Form.Item>

            <Form.Item
              className="username"
              label={<span style={{ color: "#007185" }}>Mật khẩu</span>}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập đầy đủ thông tin",
                },
                {
                  min: 6,
                  message: "Mật khẩu tối thiểu phải đủ 6 ký tự",
                },
              ]}
            >
              <Input.Password
                style={{ padding: "0px 10px", borderRadius: 5 }}
                type="password"
                placeholder="Nhập mật khẩu của bạn"
              />
            </Form.Item>

            <Form.Item
              name="remember"
              className="aligin-center"
              valuePropName="checked"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Checkbox.Group>
                    <Checkbox value="rules">Nhớ mật khẩu</Checkbox>
                  </Checkbox.Group>
                </div>
                <div
                  onClick={() => setOpenForgot(true)}
                  style={{
                    color: "#007185",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Quên mật khẩu
                </div>
              </div>
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{
                  background: "#007185",
                  fontSize: 14,
                  border: "1px solid  #007185",
                  fontWeight: 500,
                }}
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default Sigin;

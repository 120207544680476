import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import History from "../routes/history";
import Helper from "./../extra/helper";
import Upanh from "../assets/images/upanh.png";
import Upfile from "../assets/images/upfile2.png";
import Ipdf from "../assets/images/ipdf.png";
import Service from "./../extra/getAPI";
import Iticktrue from "../assets/images/iticktrue.png";
import { AuthContext } from "./../context/AuthProvider";
import { Layout, Button, Row, Col, Image, message } from "antd";
const { Content } = Layout;

const Sign = () => {
  const { file, setFile, setDataOCR, dataOCR } = useContext(AuthContext);
  const [accept, setAccept] = useState(null);
  const [loading, setLoading] = useState(false);
  const importInputRef = useRef();

  const handleImportFile = (files) => {
    if (files && files[0]) {
      setFile(files[0]);
      setDataOCR(null);
    }
  };

  const OCR = (file) => {
    setLoading(true);
    if (!file) {
      message.error("Không tìm thấy hình ảnh, vui lòng thử lại!");
      setLoading(false);
      return;
    }
    const form = new FormData();
    const type = file?.type === "application/pdf" ? "pdf" : "img";
    form.append("file", file);
    Service.apicall_upload(
      "POST",
      `/api/v1/portal/ocr/vehicle?type=${type}`,
      form
    )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDataOCR(res.data.data);
          History.push("/upload-chung-tu/ocr");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const css = {
    btn: {
      background: "#ffffff",
      color: "#007185",
      textAlign: "center",
      borderRadius: 4,
      border: "1px solid #007185",
      fontWeight: "500",
      height: 40,
    },
  };

  return (
    <>
      <div style={{ marginBottom: 10 }}>Upload chứng từ</div>
      <Row
        style={{
          padding: 12,
          boxShadow: "5px 5px 10px 0px #E9E8F180",
          border: "1px solid #E9E8F180",
          borderRadius: 16,
          fontSize: 16,
        }}
        gutter={[20, 10]}
      >
        <Col md={24} xs={24} lg={12}>
          <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
            <Col
              style={{ display: "flex", alignItems: "center" }}
              xs={24}
              md={24}
              lg={12}
            >
              <div style={{ color: "#343A40", fontWeight: 500 }}>
                Tải chứng từ xác nhận từ khách hàng
              </div>
            </Col>
            <Col xs={24} md={24} lg={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    borderRadius: 4,
                    color: "#007185",
                    background: "#ffffff ",
                    width: 125,
                    padding: 0,
                    border: "1px solid #007185",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    setAccept("image/*");
                    setTimeout(() => {
                      importInputRef.current.click();
                    }, 100);
                  }}
                  type="primary"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Upanh}
                      style={{ width: 13, marginRight: 5, height: 13 }}
                    />{" "}
                    Tải lại hình ảnh
                  </div>
                </Button>
                <Button
                  style={{
                    borderRadius: 4,
                    color: "#ffffff",
                    background: "#007185 ",
                    width: 125,
                    padding: 0,
                    border: "1px solid #007185",
                  }}
                  onClick={() => {
                    setAccept("application/pdf");
                    setTimeout(() => {
                      importInputRef.current.click();
                    }, 100);
                  }}
                  type="primary"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={Upfile}
                      style={{ width: 13, marginRight: 5, height: 13 }}
                    />
                    Tải lại file PDF
                  </div>
                </Button>
              </div>
            </Col>
          </Row>
          {file?.type === "application/pdf" ? (
            <iframe
              style={{ width: "100%", height: 342 }}
              src={file ? URL.createObjectURL(file) : ""}
              title="W3Schools Free Online Web Tutorials"
            ></iframe>
          ) : (
            <div
              style={{
                padding: 10,
                border: "1px dashed #969696",
                borderRadius: 8,
              }}
            >
              <Image
                src={file ? URL.createObjectURL(file) : ""}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </Col>
        <Col md={0} xs={0} lg={12}>
          <div style={{ color: "#343A40", fontWeight: 500 }}>Files</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              justifyContent: "space-between",
              marginTop: 30,
              borderBottom: "0.92px solid #D6DEEB",
              paddingBottom: 10,
              borderRadius: 4,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  file?.type === "application/pdf"
                    ? Ipdf
                    : URL.createObjectURL(file)
                }
                style={{ marginRight: 10, width: 25, height: 25 }}
              />
              <span style={{ marginRight: 10 }}>{file?.name}</span>
              <span style={{ marginRight: 10 }}>
                {Helper._formatDay(new Date())}
              </span>
              <span style={{ marginRight: 10 }}>{+file?.size / 1000} kb</span>
            </div>
            <div>
              <img src={Iticktrue} />
            </div>
          </div>
        </Col>
        <Col md={24} xs={24} lg={0}>
          <div style={{ color: "#343A40", fontWeight: 500 }}>Files</div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 12,
              justifyContent: "space-between",
              marginTop: 30,
              borderBottom: "0.92px solid #D6DEEB",
              paddingBottom: 10,
              borderRadius: 4,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent:'space-between'}}>
              <img
                src={
                  file?.type === "application/pdf"
                    ? Ipdf
                    : URL.createObjectURL(file)
                }
                style={{ marginRight: 10, width: 25, height: 25 }}
              />
              <span style={{ marginRight: 10 }}>{file?.name}</span>
              <span style={{ marginRight: 10 }}>
                {Helper._formatDay(new Date())}
              </span>
            </div>
            <div>
              <img src={Iticktrue} />
            </div>
          </div>
        </Col>
      </Row>
      <div style={{ marginBottom: 20 }}>
        <Row>
          <Col xs={2} xl={0}></Col>
          <Col xs={20} xl={24} style={{ width: "90%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 20,
                width: "100%",
              }}
            >
              <Button style={css.btn}>
                <Link to={"/upload-chung-tu"}>
                  <b>Quay lại</b>
                </Link>
              </Button>
              <Button
                loading={loading}
                htmlType="submit"
                onClick={() => {
                  if (dataOCR) {
                    History.push("/upload-chung-tu/ocr");
                    return;
                  }
                  OCR(file);
                }}
                style={{
                  ...css.btn,
                  background: "#007185",
                  color: "#ffffff",
                }}
              >
                <b>Tiến hành OCR</b>
              </Button>
            </div>
          </Col>
          <Col xs={2} xl={0}>
            {" "}
          </Col>
        </Row>
      </div>
      <input
        ref={importInputRef}
        //capture="camera"
        type="file"
        accept={accept}
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => {
          handleImportFile(e.target.files);
        }}
        style={{ display: "none" }}
      />
    </>
  );
};

export default Sign;

import React, { useEffect, useState } from "react";
import ImgOCR from "../assets/images/imgocr.png";
import ContentComponent from "./../components/signIn/content";
import MdSigin from "./../components/signIn/mdSigin";
import { Animated } from "react-animated-css";
import HeaderComponent from "./../components/signIn/header";

import {
  Layout,
  Row,
  Col,
} from "antd";
import signinbg from "../assets/images/bg-signin.png";
const { Content } = Layout;
const Sign = () => {
  const [loading, setLoading] = useState(false);
  const [openSigin, setOpenSigin] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);

  const handleCancel = () => {
    setOpenSigin(false);
    setOpenForgot(false);
  };

  return (
    <>
      <MdSigin
        openForgot={setOpenForgot}
        open={openSigin}
        handleCancel={handleCancel}
      />
      <Row>
        <Col md={0} xs={0} lg={24}>
          <Layout
            style={{
              height: "100vh",
              backgroundImage: `url(${signinbg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "55% 100%",
              backgroundPosition: "right"
            }}
            className="layout-default layout-signin"
          >
            <HeaderComponent openSigin={setOpenSigin} bgcolor={"#ffffff00"} />
            <Content
              style={{ alignItems: "center", display: "flex", marginTop:'-10vh'}}
              className="signin"
            >
              <Row style={{ width: "100%"}}>
                <Col xs={10}>
                  <Animated
                    animationIn="fadeInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <img src={ImgOCR} style={{ minWidth: "56vw" }} />
                  </Animated>
                </Col>
                <Col
                  // className="sign-img"
                  style={{
                    padding: 12,
                    whiteSpace: "break-spaces",
                    // background: "#007185",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#ffffff",
                    paddingLeft: "10%",
                  }}
                  xs={14}
                >
                  <Animated
                    animationIn="fadeInRight"
                    animationInDuration={500}
                    isVisible={true}
                  >
                  <div style={{ textAlign: "center" }}>
                    <ContentComponent
                      openSigin={setOpenSigin}
                      size={[28, 20, 24, 20]}
                    />
                  </div>
                  </Animated>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Col>
        <Col md={24} lg={0} xs={24}>
          <Layout
            style={{
              minHeight: "100vh",
              background: "#007185",
              width: "100%",
            }}
            className="layout-default layout-signin"
          >
            <HeaderComponent colorMobile={['#ffffff','#007185']} openSigin={setOpenSigin} bgcolor={"#ffffff"} />
            <Content
            // className="signin"
            >
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    whiteSpace: "break-spaces",
                    color: "#ffffff",
                  }}
                >
                      
                  <ContentComponent 
                      openSigin={setOpenSigin}
                  size={[18, 14, 24, 17]} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <img src={ImgOCR} style={{ width: "70%" }} />
                </div>
              </div>
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
};

export default Sign;

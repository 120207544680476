import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Button,
  Input,
  Checkbox,
  Radio
} from "antd";
import Service from "./../extra/getAPI";
import Helper from "./../extra/helper";
import { AuthContext } from "./../context/AuthProvider";
import { Link } from "react-router-dom";

export default function UpdateAccount() {
  const {
    agency,
    department,
    Department,
    MesSucess,
    profile,
    Agency,
    setDepartment,
  } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [linkAvatar, setLinkAvatar] = useState();
  const [detail, setDetail] = useState({});
  const uid = Helper.getParamUrlByKey("id");
  const { Option } = Select;
  const rolesUser = profile?.extra_info?.roles;
  const [newAgency, setNewAgency] = useState([]);
  const [newDeparment, setNewDeparment] = useState([]);
  const [loadingAgency, setLoadingAgency] = useState(false);
  const [loadingDepartment, setLoadingDepartment] = useState(false);
  const [uidAgency, setUidAgency] = useState(null);

  const checkAllUser = [
    "user all",
    "user view",
    "user create",
    "user update",
    "user report",
  ];

  const checkAllReport = ["report all", "report view", "report report"];

  useEffect(() => {
    GetDetail();
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        const data = {
          ...values,
          agency: values?.agency
            ? Helper.getValueKeyText(values?.agency)?.id
            : null,
          department: values?.department
            ? Helper.getValueKeyText(values?.department)?.id
            : null,
          roles: [
            ...values?.rolesUser,
            ...values?.rolesReport,
            ...values?.rolesRequest,
          ],
        };
        Update(data);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  // Tạo tài khoản bắt trùng email
  const Update = (values) => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/portal/user/${uid}`, values)
      .then((res) => {
        if (res.data.statusCode === 200) {
          MesSucess("Cập nhật tài khoản thành công!");
          GetDetail();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetDetail = async () => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/portal/user/${uid}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          const result = res.data.result;
          if (result?.agency_public_uid) {
            Department(result?.agency_public_uid);
          }
          setDetail(result);
          setLinkAvatar(result?.extra_info?.avatar);
          setUidAgency(result?.agency_public?.uid);
          form.setFieldsValue({
            agency: result?.agency_public?.uid
              ? result?.agency_public?.uid + "/" + result?.agency_public?.title
              : null,
            department: result?.department_public?.uid
              ? result?.department_public?.uid +
                "/" +
                result?.department_public?.title
              : null,
            fullname: result?.fullname,
            email: result?.email,
            avatar: "",
            phone: result?.phone_number,
            address: result?.address,
            tax: result?.tax_code,
            status: result?.status,
            rolesUser: checkAllUser.filter((item) =>
              result.extra_info.roles.includes(item)
            ),
            rolesReport: checkAllReport.filter((item) =>
              result.extra_info.roles.includes(item)
            ),
            rolesRequest: ["request upload"].filter((item) =>
              result.extra_info.roles.includes(item)
            ),
          });
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onchange = (e) => {
    setTimeout(() => {
      const listCheckUser = form.getFieldValue("rolesUser");
      if (!listCheckUser.includes("user all")) {
        if (listCheckUser.length === 4) {
          form.setFieldsValue({
            rolesUser: checkAllUser,
          });
        }
      } else {
        if (listCheckUser.length < 5) {
          form.setFieldsValue({
            rolesUser: listCheckUser.filter((item) => item !== "user all"),
          });
        }
      }
    }, 200);
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setTimeout(() => {
        form.setFieldsValue({
          rolesUser: checkAllUser,
        });
      }, 200);
    } else {
      setTimeout(() => {
        form.setFieldsValue({
          rolesUser: [],
        });
      }, 200);
    }
  };

  const onchangeReport = (e) => {
    setTimeout(() => {
      const listCheckUser = form.getFieldValue("rolesReport");
      if (!listCheckUser.includes("report all")) {
        if (listCheckUser.length === 2) {
          form.setFieldsValue({
            rolesReport: checkAllReport,
          });
        }
      } else {
        if (listCheckUser.length < 3) {
          form.setFieldsValue({
            rolesReport: listCheckUser.filter((item) => item !== "report all"),
          });
        }
      }
    }, 200);
  };

  const onCheckAllChangeReport = (e) => {
    if (e.target.checked) {
      setTimeout(() => {
        form.setFieldsValue({
          rolesReport: checkAllReport,
        });
      }, 200);
    } else {
      setTimeout(() => {
        form.setFieldsValue({
          rolesReport: [],
        });
      }, 200);
    }
  };

  const CreateAgency = (title) => {
    setLoadingAgency(true);
    setLoading(true);
    const data = {
      title: title,
    };
    Service.apicall("POST", `/api/v1/portal/agency-public`, data)
      .then((res) => {
        setLoading(false);
        setLoadingAgency(false);
        if (res.data.statusCode === 200) {
          Agency();
          setNewAgency([]);
          setUidAgency(res.data.result[0]?.uid);
          setTimeout(() => {
            form.setFieldsValue({
              agency: res.data.result[0]?.uid + "/" + res.data.result[0]?.title,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingAgency(false);
      });
  };

  const CreateDepartment = (title) => {
    setLoadingDepartment(true);
    setLoading(true);
    const data = {
      agency_public_id: uidAgency,
      title: title,
    };
    Service.apicall("POST", `/api/v1/portal/department-public`, data)
      .then((res) => {
        setLoadingDepartment(false);
        setLoading(false);

        if (res.data.statusCode === 200) {
          Department(uidAgency);
          setNewDeparment([]);
          setTimeout(() => {
            form.setFieldsValue({
              department:
                res.data.result[0]?.uid + "/" + res.data.result[0]?.title,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);

        setLoadingDepartment(false);
      });
  };

  const css = {
    input: {
      width: "100%",
      color: "#141414",
      fontWeight: 400,
      height: 40,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      border: "1px solid #D2D2D2",
    },
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <span style={{ color: "#969696" }}>Quản lý tài khoản / </span>
        <span style={{ textTransform: "capitalize" }}>{detail?.fullname}</span>
      </div>
      <div>
        <Form
          layout="vertical"
          ref={formRef}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 16,
            }}
          >
            <Row gutter={[10, 0]}>
              <Col xl={24}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 10,
                    color: "#007185",
                  }}
                >
                  Thông tin tài khoản
                </div>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username"
                  label={
                    <span style={{ fontWeight: 400 }}>
                      Chi nhánh / Công ty con
                    </span>
                  }
                  name="agency"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Select
                    disabled={
                      +detail?.merchant_level_id === 2 ||
                      +detail?.merchant_level_id === 3
                        ? true
                        : false
                    }
                    allowClear={true}
                    className="custom-select2"
                    placeholder="Nhập / chọn thông tin"
                    optionFilterProp="children"
                    loading={loadingAgency}
                    onClear={() => {
                      form.setFieldsValue({ department: null });
                      setDepartment([]);
                    }}
                    showSearch
                    onChange={(e) => {
                      if (e !== 0) {
                        Department(Helper.getValueKeyText(e)?.id);
                        form.setFieldsValue({ department: null });
                        setUidAgency(Helper.getValueKeyText(e)?.id);
                      } else {
                        CreateAgency(newAgency[0].title);
                      }
                    }}
                    onSearch={(e) => {
                      if (e.length > 0) {
                        setNewAgency([{ title: e }]);
                      }
                    }}
                  >
                    {newAgency?.map(
                      (item, index) =>
                        agency.filter((o) => o.title == newAgency[0].title)
                          .length === 0 && (
                          <Option key={index} value={0}>
                            {item?.title}
                          </Option>
                        )
                    )}
                    {agency?.map((item, index) => (
                      <Option key={index} value={item?.uid + "/" + item?.title}>
                        {item?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username"
                  label={
                    <span style={{ fontWeight: 400 }}>Phòng ban / đơn vị</span>
                  }
                  name="department"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Select
                    allowClear={true}
                    className="custom-select2"
                    placeholder="Nhập / chọn thông tin"
                    optionFilterProp="children"
                    loading={loadingDepartment}
                    showSearch
                    onChange={(e) => {
                      if (e === 0) {
                        CreateDepartment(newDeparment[0].title);
                      }
                    }}
                    onSearch={(e) => {
                      if (e.length > 0) {
                        setNewDeparment([{ title: e }]);
                      }
                    }}
                  >
                    {newDeparment?.map(
                      (item, index) =>
                        department.filter(
                          (o) => o.title == newDeparment[0].title
                        ).length === 0 && (
                          <Option key={index} value={0}>
                            {item?.title}
                          </Option>
                        )
                    )}
                    {department?.map((item, index) => (
                      <Option key={index} value={item?.uid + "/" + item?.title}>
                        {item?.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username custom-input"
                  label={<span style={{ fontWeight: 400 }}>Tên User</span>}
                  name="fullname"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Input
                    allowClear
                    style={{ ...css.input, textTransform: "capitalize" }}
                    placeholder="Nhập thông tin"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username"
                  label={<span style={{ fontWeight: 400 }}>Email</span>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                    {
                      message: "Vui lòng nhập đúng email.",
                      pattern: /\S+@\S+\.\S+/,
                    },
                  ]}
                >
                  <Input
                    disabled
                    type="email"
                    style={css.input}
                    placeholder="Nhập thông tin"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username custom-input"
                  label={<span style={{ fontWeight: 400 }}>Số điện thoại</span>}
                  name="phone"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                    {
                      message: "Vui lòng nhập đúng số điện thoại",
                      pattern: /((09|03|07|08|05)+([0-9]{8})\b)/,
                    },
                  ]}
                >
                  <Input
                    type="tel"
                    allowClear
                    style={css.input}
                    placeholder="Nhập thông tin"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username custom-input"
                  label={<span style={{ fontWeight: 400 }}>Địa chỉ</span>}
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Input
                    allowClear
                    style={{ ...css.input, textTransform: "capitalize" }}
                    placeholder="Nhập thông tin"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username custom-input"
                  label={<span style={{ fontWeight: 400 }}>Mã số thuế</span>}
                  name="tax"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Input
                    allowClear
                    style={css.input}
                    placeholder="Nhập thông tin"
                  />
                </Form.Item>
              </Col>
              <Col xl={6} xs={24}>
                <Form.Item
                  className="username"
                  label={<span style={{ fontWeight: 400 }}>Trạng thái</span>}
                  name="status"
                  rules={[
                    {
                      required: false,
                      message: "Vui lòng nhập đầy đủ thông tin.",
                    },
                  ]}
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row>
                      <Col
                        style={{ padding: 0, marginRight: -20 }}
                        xs={5}
                        xl={12}
                      >
                        <Radio value={1}>Hoạt động</Radio>
                      </Col>
                      <Col style={{ padding: 0, margin: 0 }} xs={19} xl={12}>
                        <Radio
                          style={{ wordWrap: "normal", width: 140 }}
                          value={2}
                        >
                          Không hoạt động
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 16,
              marginTop: 20,
            }}
          >
            <Row gutter={[10, 20]}>
              <Col xl={24}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 10,
                    color: "#007185",
                  }}
                >
                  Quyền quản lý
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 135 }}>Quản lý tài khoản</div>

                  <Form.Item
                    className="username"
                    name="rolesUser"
                    style={{
                      width: "100%",
                      padding: "0px 8px",
                      margin: 0,
                      background: "#F8F9FA",
                      borderRadius: 5,
                    }}
                    rules={[
                      {
                        required: false,
                        message: "Vui lòng nhập đầy đủ thông tin.",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      onChange={onchange}
                      style={{ width: "100%" }}
                    >
                      <Row style={{marginLeft: 0}}>
                        <Col xs={24} xl={4}>
                          <Checkbox
                            onChange={onCheckAllChange}
                            value="user all"
                          >
                            Toàn quyền
                          </Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="user view">Xem</Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="user create">Thêm</Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="user update">Sửa</Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="user report">Xuất báo cáo</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 135 }}>Quản lý báo cáo</div>
                  <Form.Item
                    className="username"
                    name="rolesReport"
                    style={{
                      width: "100%",
                      padding: "0px 8px",
                      margin: 0,
                      background: "#F8F9FA",
                      borderRadius: 5,
                    }}
                    rules={[
                      {
                        required: false,
                        message: "Vui lòng nhập đầy đủ thông tin.",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      onChange={onchangeReport}
                      style={{ width: "100%" }}
                    >
                      <Row style={{marginLeft: 0}}>
                        <Col xs={24} xl={4}>
                          <Checkbox
                            onChange={onCheckAllChangeReport}
                            value="report all"
                          >
                            Toàn quyền
                          </Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="report view">Xem</Checkbox>
                        </Col>
                        <Col xs={24} xl={4}>
                          <Checkbox value="report report">
                            Xuất báo cáo
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </Col>
              <Col xl={24} xs={24}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 135 }}>Quản lý yêu cầu</div>
                  <Form.Item
                    className="username"
                    name="rolesRequest"
                    style={{
                      width: "100%",
                      padding: "0px 8px",
                      margin: 0,
                      background: "#F8F9FA",
                      borderRadius: 5,
                    }}
                    rules={[
                      {
                        required: false,
                        message: "Vui lòng nhập đầy đủ thông tin.",
                      },
                    ]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row style={{marginLeft: 0}}>
                        <Col xl={24}>
                          <Checkbox value="request upload">
                            Upload Chứng từ
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Row>
              <Col xs={4} xl={0}></Col>
              <Col xs={16} xl={24} style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <Button
                    style={{
                      background: "#ffffff",
                      color: "#007185",
                      width: "90px",
                      textAlign: "center",
                      borderRadius: 9,
                      border: "1px solid #007185",
                      fontWeight: "500",
                      height: 40,
                    }}
                  >
                    <Link to={"/quan-ly-tai-khoan"}>
                      <b>Quay lại</b>
                    </Link>
                  </Button>
                  <Button
                    disabled={!rolesUser?.includes("user update")}
                    htmlType="submit"
                    loading={loading}
                    style={{
                      background: rolesUser?.includes("user update")
                        ? "#007185"
                        : "#969696",
                      color: "#ffffff",
                      width: "123px",
                      textAlign: "center",
                      borderRadius: 9,
                      // border: "1px solid #007185",
                      fontWeight: "500",
                      height: 40,
                    }}
                  >
                    <b>Cập nhật</b>
                  </Button>
                </div>
              </Col>
              <Col xs={4} xl={0}>
                {" "}
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
}

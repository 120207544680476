import React, { useEffect, useState } from "react";
import { Button, Modal,Form,Input,message} from "antd";
import Service from "../../extra/getAPI";


function ForgotPass(props) {
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    ResetPass(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const ResetPass = (values) => {
    setLoading(true);
    const data = {
      email: values.email,
    };
    Service.apicall("POST", `/api/v1/portal/user/reset-password`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
            message.success('Mật khẩu đã được gửi về email của bạn!');
            props?.handleCancel();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth:'350px'}}
      >
        <div style={{ textAlign: "center", fontSize: 24, color: "#007185" }}>
          <b>Quên Mật Khẩu</b>
        </div>
        <div style={{margin:'10px 0px'}}>Mật khẩu được gửi về email mà bạn đã đăng ký.</div>
        <div>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Form.Item
              className="username"
              label={<><span style={{ color: "#007185" }}>Email</span><span style={{color:'red'}}>*</span></>}
              name="email"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
                {
                  message: "Vui lòng nhập đúng email.",
                  pattern: /\S+@\S+\.\S+/,
                }
              ]}
            >
              <Input placeholder="Nhập email của bạn" />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                style={{ background: "#007185", fontSize: 14, border:'1px solid #007185', fontWeight: 600}}
              >
                Lấy lại mật khẩu
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default ForgotPass;

import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, message } from "antd";
import Service from "../../extra/getAPI";
import { AuthContext } from "./../../context/AuthProvider";
import Warning from "./../../assets/images/warning.png";
import Success from "./../../assets/images/succes.png";
import History from "../../routes/history";


function NewPass(props) {
  const { profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const NewPassword = () => {
    setLoading(true);
    Service.apicall("PUT", `/api/v1/portal/user/${profile?.uid}/password`)
      .then((res) => {
        setLoading(false);
        if (res.data.statusCode === 200) {
          message.success("Mật khẩu đã được gửi về email của bạn!");
          setDone(true);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        // onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "350px" }}
      >
        <div style={{ textAlign: "center", fontSize: 18 }}>
          <div>
            <img src={done ? Success : Warning} style={{ width: 80 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <b>{done ? "Thành công" : "Thông báo"}</b>
          </div>
        </div>
        {done && (
          <div style={{ margin: "10px 0px", fontSize: 14 }}>
            Bạn đã tạo mới mật khẩu thành công, mật khẩu mới đã được gửi vào
            email <b>{profile?.email}</b>.
          </div>
        )}
        {!done && (
          <div style={{ margin: "10px 0px", fontSize: 14 }}>
            Bạn có muốn tạo mới mật khẩu không?
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
          {!done && (
            <>
              <Button
                onClick={() => props?.handleCancel()}
                type="primary"
                style={{
                  background: "#fff",
                  fontSize: 14,
                  border: "1px solid #007185",
                  fontWeight: 600,
                  width: "48%",
                  color: "#007185",
                }}
              >
                Không
              </Button>
              <Button
                loading={loading}
                onClick={() => NewPassword()}
                type="primary"
                style={{
                  background: "#007185",
                  fontSize: 14,
                  border: "1px solid #007185",
                  fontWeight: 600,
                  width: "48%",
                }}
              >
                Có
              </Button>
            </>
          )}
          {done && (
            <Button
              onClick={() => {
                props?.handleCancel();
                localStorage.clear();
                History.push("/sign-in");
              }}
              type="primary"
              style={{
                background: "#007185",
                fontSize: 14,
                border: "1px solid #007185",
                fontWeight: 600,
                width: "100%",
                color: "#fff",
              }}
            >
              Đóng
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}

export default NewPass;

import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form, Input, message } from "antd";
import Service from "../../extra/getAPI";
import { AuthContext } from "./../../context/AuthProvider";
import Warning from "./../../assets/images/warning.png";
import Success from "./../../assets/images/succes.png";
import History from "../../routes/history";

function MDWarning(props) {
  const { profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const Send = () => {
    props.onFinish();
    props.handleCancel();
  };

  return (
    <>
      <Modal
        footer={false}
        className="modal-custom"
        visible={props?.open}
        centered={true}
        closable={false}
        // onCancel={props?.handleCancel}
        style={{ borderRadius: 10, maxWidth: "350px" }}
      >
        <div style={{ textAlign: "center", fontSize: 18 }}>
          <div>
            <img src={Warning} style={{ width: 80 }} />
          </div>
          <div style={{ marginTop: 10 }}>
            <b>Thông báo</b>
          </div>
        </div>
        <div
            style={{ margin: "10px 0px", fontSize: 14, textAlign: "center" }}
          >
            {props?.messWarning}
          </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 15,
          }}
        >
            <Button
              onClick={() => {
                props?.handleCancel();
              }}
              type="primary"
              style={{
                background: "#007185",
                fontSize: 14,
                border: "1px solid #007185",
                fontWeight: 600,
                width: "100%",
                color: "#fff",
              }}
            >
              Quay lại
            </Button>
        </div>
      </Modal>
    </>
  );
}

export default MDWarning;

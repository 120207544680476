import React, { useEffect, useState, useContext, useRef } from "react";
import ImgOCR from "../assets/images/imgocr.png";
import ContentComponent from "./../components/signIn/content";
import { Animated } from "react-animated-css";

import {
  Layout,
  Row,
  Col
} from "antd";
import signinbg from "../assets/images/bg-signin.png";
const { Content } = Layout;
const Header = () => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>Upload chứng từ</div>
      <Row style={{ height: "100%" }}>
        <Col md={0} xs={0} lg={24}>
          <Layout
            style={{
              minHeight: "97%",
              backgroundImage: `url(${signinbg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "65% 100%",
              backgroundPosition: "right",
              margin: "0px",
              borderRadius: 16,
              boxShadow: "5px 5px 10px 0px #E9E8F180",
              border: "1px solid #E9E8F180",
            }}
            className="layout-default layout-signin"
          >
            <Content
              style={{ alignItems: "center", display: "flex" }}
              className="signin"
            >
              <Row style={{ width: "100%" }}>
                <Col xs={10} style={{ display: "flex", alignItems: "center" }}>
                  <Animated
                    animationIn="fadeInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <img src={ImgOCR} style={{ minWidth: "40vw" }} />
                  </Animated>
                </Col>
                <Col
                  // className="sign-img"
                  style={{
                    padding: 12,
                    whiteSpace: "break-spaces",
                    // background: "#007185",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#ffffff",
                    paddingLeft: "10%",
                  }}
                  xs={14}
                >
                  <Animated
                    animationIn="fadeInRight"
                    animationInDuration={500}
                    isVisible={true}
                  >
                  <div style={{ textAlign: "center" }}>
                    <ContentComponent
                      // openSigin={setOpenSigin}
                      size={[28, 20, 24, 20]}
                    />
                  </div>
                  </Animated>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Col>
        <Col md={24} lg={0} xs={24}>
          <Layout
            style={{
              minHeight: "100%",
              background: "#007185",
              width: "100%",
            }}
            className="layout-default layout-signin"
          >
            <Content
            // className="signin"
            >
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    whiteSpace: "break-spaces",
                    color: "#ffffff",
                    paddingTop: 10,
                  }}
                >
                  <ContentComponent size={[18, 14, 24, 17]} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10%",
                  }}
                >
                  <img src={ImgOCR} style={{ width: "70%" }} />
                </div>
              </div>
            </Content>
          </Layout>
        </Col>
      </Row>
    </>
  );
};

export default Header;

import React, { useState, useEffect, useRef } from "react";
import Service from "./../extra/getAPI";
import { message } from "antd";

export const AuthContext = React.createContext();
export default function AuthProvider({ children }) {
  const [profile, setProfileGlobal] = useState({});
  const [detail, setDetail] = useState({});
  const [agency, setAgency] = useState([]);
  const [department, setDepartment] = useState([]);
  const [file, setFile] = useState(null);
  const [dataOCR, setDataOCR] = useState(null);

  useEffect(() => {
    const login = localStorage.getItem("login");
    if(login){
      Agency();
      GetProfile();
    }
  }, [localStorage.getItem("login")]);

  // Lấy danh sách chi nhánh công ty con
  const Agency = () => {
    Service.apicall("GET", `/api/v1/portal/agency-public`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setAgency(res.data.result);
        }
      })
      .catch((err) => {});
  };

  // Lấy danh sách phòng ban đơn vị
  const Department = (agency) => {
    Service.apicall("GET", `/api/v1/portal/department-public?agency=${agency}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDepartment(res.data.result);
        }
      })
      .catch((err) => {});
  };

  // Lấy thông tin tài khoản
  const GetProfile = () => {
    Service.apicall("GET", `/api/v1/portal/user/profile`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setProfileGlobal(res.data.result);
        }
      })
      .catch((err) => {});
  };

  const MesSucess = (content) => {
    message.success(content);
  };

  return (
    <AuthContext.Provider
      value={{
        profile,
        detail,
        setDetail,
        file,
        setFile,
        agency,
        department,
        Department,
        MesSucess,
        setProfileGlobal,
        GetProfile,
        dataOCR, setDataOCR,
        setAgency,
        Agency,
        setDepartment
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

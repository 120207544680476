import {
  Switch,
  Route,
  Redirect,
  Router,
  BrowserRouter,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import AuthProvider from "./context/AuthProvider";
import Account from "./pages/quan-ly-tai-khoan";
import InfomationAccount from "./pages/thong-tin-tai-khoan";
import Taotaikhoan from "./pages/tao-tai-khoan";
import Capnhattaikhoan from "./pages/cap-nhat-tai-khoan";
import OCR from "./pages/ocr";
import Xemhinh from "./pages/xem-hinh-ocr";
import Quanlybaocao from "./pages/quan-ly-bao-cao";
import UploadDocuments from "./pages/upload-chung-tu";
import SignIn from "./pages/dangnhap";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import History from "./routes/history";


function App() {
  
  useEffect(() => {
    const login = localStorage.getItem("login");
    if (!login) {
      History.push("/sign-in");
    } else {
      // History.push("/upload-chung-tu");
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Router history={History}>
            <Switch>
              <Route path="/sign-in" exact component={SignIn} />
              <Main>
                <Route exact path="/quan-ly-tai-khoan" component={Account} />
                <Route exact path="/thong-tin-tai-khoan" component={InfomationAccount} />
                <Route exact path="/quan-ly-tai-khoan/tao-tai-khoan" component={Taotaikhoan} />
                <Route exact path="/quan-ly-tai-khoan/cap-nhat-tai-khoan" component={Capnhattaikhoan} />
                <Route exact path="/quan-ly-bao-cao" component={Quanlybaocao} />
                <Route exact path="/upload-chung-tu" component={UploadDocuments} />
                <Route exact path="/upload-chung-tu/ocr" component={OCR} />
                <Route exact path="/upload-chung-tu/xem-hinh" component={Xemhinh} />
                <Redirect from="*" to="/quan-ly-tai-khoan" />
              </Main>
            </Switch>
          </Router>
        </AuthProvider>
      </BrowserRouter>
      </>);
}

export default App;

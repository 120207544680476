import React, { useState, useContext, useEffect, useRef } from "react";
import { UserOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Avatar,
  message
} from "antd";
import Service from "./../extra/getAPI";
import { AuthContext } from "./../context/AuthProvider";
import History from "./../routes/history";
import Upanh from "../assets/images/upanh2.png";
import NewPass from "./../components/signIn/mdNewPass";

export default function Infomation() {
  const { MesSucess, setProfileGlobal } = useContext(AuthContext);
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [openNewPass, setOpenNewPass] = useState(false);
  const [levelUser, setLevelUser] = useState(null);
  const [file, setFile] = useState(null);
  const [linkAvatar, setLinkAvatar] = useState();
  const importInputRef = useRef();

  useEffect(() => {
    GetProfile();
  }, []);

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        UpdateProfile(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const UpdateProfile = (values) => {
    setLoading(true);
    const data = {
      agency: profile?.agency_public?.uid,
      department: profile?.department_public?.uid,
      fullname: values?.fullname,
      avatar: linkAvatar || '',
      phone: values?.phone,
      address: values?.address,
      tax: values?.tax,
      roles: profile?.extra_info?.roles,
      status: profile?.status,
    };
    Service.apicall("PUT", `/api/v1/portal/user/${profile?.uid}`, data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setLoading(false);
          GetProfile();
          MesSucess("Cập nhật tài khoản thành công!");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetProfile = () => {
    setLoading(true);
    Service.apicall("GET", `/api/v1/portal/user/profile`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          const result = res.data.result;
          setProfile(result);
          setProfileGlobal(result);
          setLevelUser(result?.merchant_level_id);
          setLinkAvatar(result?.extra_info?.avatar);
          form.setFieldsValue({
            agency: result?.agency_public?.title,
            department: result?.department_public?.title,
            fullname: result?.fullname,
            merchantTitle: result?.merchant?.title,
            email: result?.email,
            avatar: "Nghị chan",
            phone: result?.phone_number,
            address: result?.address,
            tax: result?.tax_code,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setOpenNewPass(false);
  };

  const handleImportFile = (files) => {
    if (files && files[0]) {
      setFile(URL.createObjectURL(files[0]));
      uploadG2(files[0]);
    }
  };

  const uploadG2 = (file) => {
    setLoading(true);
    const storage = 's3';
    var form = new FormData();
    form.append("storage", storage);
    form.append("file", file);
    form.append("path", `/products/images/`);
    Service.apicall_upload_G2("POST", `/upload`, form)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.complete) {
          setLinkAvatar(data?.link);
        } else {
          message.error("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error("Có lỗi xảy ra. Vui lòng chụp lại ảnh!");
      });
  };

  return (
    <>
      <NewPass open={openNewPass} handleCancel={handleCancel} />
      <div style={{ marginBottom: 20 }}>Thông tin tài khoản</div>
      <Row className="FormThongtin" style={{ overflow: "hidden" }}>
        <Form ref={formRef} form={form} onFinish={onFinish} autoComplete="off">
          <div
            style={{
              background: "#ffffff",
              width: "100%",
              padding: 20,
              borderRadius: 16,
            }}
          >
            <Row gutter={[60, 0]}>
              <Col xl={24}>
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 10,
                    color: "#007185",
                  }}
                >
                  Thông tin tài khoản
                </div>
              </Col>
              <Col xl={24}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div>
                    <Avatar
                      size={80}
                      style={{
                        backgroundColor: "#87d068",
                        width: 80,
                        height: 80,
                      }}
                      // icon={<UserOutlined />}
                      src={linkAvatar || <UserOutlined />}
                    />
                  </div>
                  <div style={{ marginLeft: 10 }}>
                    <div
                      onClick={() =>
                        // message.error("Chức năng đang được cập nhật");
                        {
                          importInputRef.current.click();
                        }
                      }
                      style={{
                        fontSize: 14,
                        marginBottom: 10,
                        background: "#007185",
                        width: "fit-content",
                        borderRadius: 5,
                        padding: "5px 8px",
                        color: "#fff",
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img src={Upanh} style={{ marginRight: 5 }} />
                      Tải ảnh lên
                    </div>
                    <div>
                      Định dạng .JPG, .JPEG, .PNG dung lượng thấp hơn 300 KB với
                      kích thước tối thiểu 300x300 px
                    </div>
                  </div>
                </div>
                <hr className="gradient-line" />
              </Col>
              <Col xl={12} xs={24}>
                <Row gutter={[20]}>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Tên user</span>}
                      name="fullname"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Tên công ty</span>
                      }
                      name="merchantTitle"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col
                    style={{ display: +levelUser === 1 ? "none" : "" }}
                    xs={24}
                  >
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Chi nhánh / Công ty con
                        </span>
                      }
                      name="agency"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      display:
                        +levelUser === 1 || +levelUser === 2 ? "none" : "",
                    }}
                    xs={24}
                  >
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>
                          Phòng ban / đơn vị
                        </span>
                      }
                      name="department"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Địa chỉ</span>}
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} xs={24}>
                <Row gutter={[20, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={<span style={{ fontWeight: 400 }}>Email</span>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input disabled placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Số điện thoại</span>
                      }
                      name="phone"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      className="username"
                      style={{ marginBottom: 10 }}
                      label={
                        <span style={{ fontWeight: 400 }}>Mã số thuế</span>
                      }
                      name="tax"
                      rules={[
                        {
                          required: false,
                          message: "Vui lòng nhập đầy đủ thông tin.",
                        },
                      ]}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} xl={0} style={{ padding: 0}}>
                    <div style={{ marginTop: 10}}>
                      <Button
                        onClick={() => setOpenNewPass(true)}
                        style={{
                          background: "#007185",
                          color: "#ffffff",
                          textAlign: "center",
                          borderRadius: 9,
                          fontWeight: "500",
                          height: 40
                        }}
                      >
                        <b>Tạo mới mật khẩu</b>
                      </Button>
                    </div>
                  </Col>
                  <Col xs={0} xl={24}>
                    <div style={{ marginTop: 10 }}>
                      <Button
                        onClick={() => setOpenNewPass(true)}
                        style={{
                          background: "#007185",
                          color: "#ffffff",
                          textAlign: "center",
                          borderRadius: 9,
                          fontWeight: "500",
                          height: 40,
                        }}
                      >
                        <b>Tạo mới mật khẩu</b>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <Row>
              <Col xs={2} xl={0}></Col>
              <Col xs={20} xl={24} style={{ width: "90%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: 20,
                    width: "100%",
                  }}
                >
                  <Button
                    onClick={() => History.goBack()}
                    style={{
                      background: "#ffffff",
                      color: "#007185",
                      width: "90px",
                      textAlign: "center",
                      borderRadius: 9,
                      border: "1px solid #007185",
                      fontWeight: "500",
                      height: 40,
                    }}
                  >
                    <b>Quay lại</b>
                  </Button>
                  <Button
                    loading={loading}
                    htmlType="submit"
                    style={{
                      background: "#007185",
                      color: "#ffffff",
                      textAlign: "center",
                      borderRadius: 9,
                      border: "1px solid #007185",
                      fontWeight: 500,
                      height: 40,
                    }}
                  >
                    <b>Lưu thông tin</b>
                  </Button>
                </div>
              </Col>
              <Col xs={2} xl={0}>
                {" "}
              </Col>
            </Row>
          </div>
        </Form>
      </Row>
      <input
        ref={importInputRef}
        //capture="camera"
        type="file"
        onClick={(e) => {
          e.target.value = "";
        }}
        accept="image/png, image/jpeg"
        onChange={(e) => {
          handleImportFile(e.target.files);
        }}
        style={{ display: "none" }}
      />
    </>
  );
}

import {
  Row,
  Col,
  Table,
  Button,
  Pagination,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import Service from "../extra/getAPI";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./../context/AuthProvider";
import ViewDetail from "./../assets/images/viewDetail.png";
import Helper from "../extra/helper";
import { exportTableToExcel } from "./../extra/export";
import Export from "./../components/table-excel/excel-quan-ly-tai-khoan";
import Itimkiem from "./../assets/images/itimkiem.png";
import IthemUser from "./../assets/images/themuser.png";
import Itaive from "./../assets/images/taive.png";

function Acount() {
  const { profile, agency, department, Department } = useContext(AuthContext);
  const [dataOrders, seDataOrders] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [offset, setOffset] = useState(1);
  const [pageS, setPageS] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [dates, setDates] = useState(null);
  const rolesUser = profile?.extra_info?.roles;

  const project = [
    {
      title: <b>STT</b>,
      align: "center",
      render: (item, record, index) => (
        <div style={{ textAlign: "center" }}>{index + 1}</div>
      ),
    },
    {
      title: "Tên User",
      render: (_, data) => <div style={{textTransform:'capitalize'}}>{data?.fullname}</div>
    },
    {
      title: "Phòng ban / đơn vị",
      render: (_, data) => <div>{data?.department_public?.title}</div>
    },
    {
      title: "Chi nhánh / Công ty con",
      render: (_, data) => <div>{data?.agency_public?.title}</div>
    },
    {
      title: "Email",
      render: (_, data) => <div>{data?.email}</div>
    },
    {
      title: "Số điện thoại",
      render: (_, data) => <div>{data?.phone_number}</div>
    },
    {
      title: "Ngày tạo",
      render: (_, data) => <div>{Helper._formatDay(data?.created_date)}</div>
    },
    {
      title: "Trạng thái",
      align: "center",
      render: (_, data) => (
        <div style={{ color: data?.status === 1 ? "#39B44A" : "#FD1D1D" }}>
          {data?.status === 1 ? "Hoạt động" : "Không hoạt động"}
        </div>
      ),
    },
    {
      title: (
        <span style={{ background: "#fff", width: "100px" }}>Chi tiết</span>
      ),
      fixed: "right",
      align: "center",
      hidden: !rolesUser?.includes("user view"),
      render: (_, data) => (
        <Link to={`/quan-ly-tai-khoan/cap-nhat-tai-khoan?id=${data?.uid}`}>
          <img src={ViewDetail} style={{ width: 25 }} />
        </Link>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    GetUser(formData);
    // exportFile();
  }, [offset]);

  const GetUser = (values) => {
    setLoading(true);
    const data = new URLSearchParams({
      page: offset,
      limit: 20,
      fromDate: Helper._formatDayNoHour2(values?.date?.[0]),
      toDate: Helper._formatDayNoHour2(values?.date?.[1]),
      search: values?.search || "",
      agency: values?.agency || "",
      department: values?.department || "",
    });
    Service.apicall("GET", `/api/v1/portal/user?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          seDataOrders(res?.data?.result);
          setPageS(res?.data?.result?.total);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const GetDataExport = (values) => {
    setLoading(true);
    if (!values?.date) {
      message.error("Vui lòng chọn ngày xuất!");
      setLoading(false);
      return;
    }
    const data = new URLSearchParams({
      page: offset,
      limit: pageS,
      fromDate: Helper._formatDayNoHour2(values?.date?.[0]),
      toDate: Helper._formatDayNoHour2(values?.date?.[1]),
      search: values?.search || "",
      agency: values?.agency || "",
      department: values?.department || "",
    });
    Service.apicall("GET", `/api/v1/portal/user?${data.toString()}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDataExport(res?.data?.result?.data);
          exportFile();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onFinish = (val) => {
    form
      .validateFields()
      .then(async (values) => {
        GetUser(values);
      })
      .catch((err) => {
        console.error("err:", err);
      });
  };

  const onChangForm = ({ data, name }) => {
    setFormData({
      ...formData,
      [name]: data,
    });
    if (name !== "search") {
      onFinish();
    }
  };

  const exportFile = async () => {
    setTimeout(() => {
      exportTableToExcel("user", `Bao-cao-tai-khoan.xlsx`);
    }, 1000);
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "months") > 0;
    const tooEarly = dates[1] && dates[1].diff(current, "months") > 0;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const css = {
    input: {
      color: "#3A3A3A",
      zIndex: 1,
      fontSize: 12,
      boxShadow: "none",
      padding: "0px 10px",
      borderRadius: 4,
      width: "100%",
      border: "1px solid #D2D2D2",
    },
    titleInput: { color: "#646D69", marginBottom: 3 },
    FormItemsHolder: {
      width: "100%",
      borderRadius: 4,
      margin: "5px 0px",
      padding: 0,
    },
    button: {
      fontSize: 12,
      background: "#007185",
      color: "#fff",
      borderRadius: 4,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
  };

  return (
    <>
      <Export data={dataExport} />
      <div>Quản lý tài khoản</div>
      <Form
        ref={formRef}
        form={form}
        onFinish={onFinish}
        style={{ fontSize: 14, width: "100%", marginBottom: 10 }}
        scrollToFirstError
      >
        <Row gutter={[10, 0]}>
          <Col xs={24}>
            <Form.Item
              name="search"
              rules={[
                {
                  required: false,
                  message: "Vui lòng nhập đầy đủ thông tin.",
                },
              ]}
              style={{ ...css.FormItemsHolder }}
            >
              <Input
                allowClear
                onChange={(e) =>
                  onChangForm({ data: e.target.value, name: "search" })
                }
                className="custom-input"
                prefix={
                  <img
                    src={Itimkiem}
                    alt="Global Care icon"
                    width={14}
                    height={14}
                  />
                }
                suffix={
                  <Button
                    onClick={() => onFinish()}
                    style={{
                      background: "#007185",
                      borderRadius: 4,
                      color: "#fff",
                      padding: "4px 10px",
                      fontWeight: 500,
                      cursor: "pointer",
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      cursor: "pointer"
                    }}
                  >
                    Tìm kiếm
                  </Button>
                }
                style={{
                  ...css.input,
                  maxWidth: 375,
                  fontSize: 12,
                }}
                placeholder="Nhập thông tin tìm kiếm..."
              />
            </Form.Item>
          </Col>
          <Col xl={5} xs={24}>
            <Form.Item
              name="agency"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>
                    Chi nhánh / Công ty con
                  </span>
                }
                onChange={(e) => {
                  onChangForm({ data: e, name: "agency" });
                  Department(e);
                }}
              >
                {agency?.map((item, index) => (
                  <Option key={index} value={item?.uid}>
                    {item?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={5} xs={24}>
            <Form.Item
              name="department"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder }}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder={
                  <span style={{ color: "#343A40" }}>Phòng ban / đơn vị</span>
                }
                onChange={(e) => {
                  onChangForm({ data: e, name: "department" });
                }}
              >
                {department?.map((item, index) => (
                  <Option key={index} value={item?.uid}>
                    {item?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xl={3} xs={24}>
            <Form.Item
              name="status"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              style={css.FormItemsHolder}
            >
              <Select
                allowClear={true}
                className="custom-select"
                placeholder="Trạng thái"
              >
                <Option value={1}>Hoạt động</Option>
                <Option value={2}>Không hoạt động</Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col xl={6} xs={24}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: false,
                  message: "",
                },
              ]}
              style={{ ...css.FormItemsHolder }}
            >
              <RangePicker
                style={{
                  // minWidth: 230,
                  width: "100%",
                  fontSize: 12,
                  // height: 35,
                  borderRadius: 4,
                  border: "1px solid #D2D2D2",
                }}
                onOpenChange={onOpenChange}
                onChange={(e) => {
                  onChangForm({ data: e, name: "date" });
                }}
                format={"DD/MM/YYYY"}
                placeholder={["Từ ngày", "Đến ngày"]}
                disabledDate={disabledDate}
                onCalendarChange={(val) => setDates(val)}
                // showTime
              />
            </Form.Item>
          </Col>
          <Col xl={8} xs={0}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: 5,
              }}
            >
              {rolesUser?.includes("user create") && (
                <Button
                  style={{ ...css.button, marginRight: 10, fontWeight: 500 }}
                >
                  <img src={IthemUser} style={{ marginRight: 5 }} />
                  <Link to={"/quan-ly-tai-khoan/tao-tai-khoan"}>
                    <span style={{ color: "#fff" }}>Thêm User</span>
                  </Link>
                </Button>
              )}
              {rolesUser?.includes("user report") && (
                <Button
                  disabled={loading}
                  onClick={() => GetDataExport(formData)}
                  style={{ ...css.button, fontWeight: 500 }}
                >
                  <img src={Itaive} style={{ marginRight: 5 }} />
                  Xuất báo cáo
                </Button>
              )}
            </div>
          </Col>
          <Col xl={0} xs={24} style={{ padding: 0 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: 10,
              }}
            >
              {rolesUser?.includes("user create") && (
                <Button
                  style={{ ...css.button, marginRight: 10, fontWeight: 500 }}
                >
                  <img src={IthemUser} style={{ marginRight: 5 }} />
                  <Link to={"/quan-ly-tai-khoan/tao-tai-khoan"}>
                    <span style={{ color: "#fff" }}>Thêm User</span>
                  </Link>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Table
              loading={loading}
              columns={project}
              dataSource={dataOrders?.data}
              pagination={false}
              className="ant-border-space taikhoan"
              rowClassName={() => "rowClassName1"}
              bordered={false}
              scroll={{ x: "auto" }}
              showHeader={true}
            />
            <div style={{ textAlign: "right", margin: "10px" }}>
              <Pagination
                className="paginateCustom2"
                total={pageS || 20}
                pageSize={20}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} trên tổng ${total} user`
                }
                defaultCurrent={1}
                defaultPageSize={0}
                // className="paginateCustom"
                onChange={(e) => {
                  setOffset(e);
                }}
                size="small"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Acount;

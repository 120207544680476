import {
  Layout,
  Button
} from "antd";
import LogoVni from "../../assets/images/logoVni.png";

function Header(props) {
  const { Header } = Layout;

  return (
    <>
      <Header style={{ boxShadow: "none", background: props.bgcolor }}>
        <div className="header-col header-brand">
          <img src={LogoVni} style={{ width: "152px" }} />
        </div>
        <div className="header-col header-nav"></div>
        <div className="header-col header-btn">
          <Button
            onClick={() => props?.openSigin(true)}
            style={{
              borderRadius: 5,
              color: props?.colorMobile ? props?.colorMobile[0]:"#007185",
              background: props?.colorMobile ? props?.colorMobile[1] : "#ffffff",
              border: "1px solid #007185",
            }}
            type="primary"
          >
            Đăng nhập
          </Button>
        </div>
      </Header>
    </>
  );
}

export default Header;

import { useState, useEffect, useContext } from "react";
import { Menu, Row, Col } from "antd";
import { useLocation, NavLink } from "react-router-dom";
import Sidebar from "../../assets/images/side-bar-image.svg";
import logo from "../../assets/images/logoVni.png";
import Itaikhoan from "../../assets/images/itaikhoan.png";
import Ibaocao from "../../assets/images/ibaocao.png";
import Iupload from "../../assets/images/iupload.png";
import Idangxuat from "../../assets/images/idangxuat.png";
import Idangxuat2 from "../../assets/images/imenudangxuat61.png";
import History from "./../../routes/history";
import { AuthContext } from "./../../context/AuthProvider";

function Sidenav({ color }) {
  const { profile } = useContext(AuthContext);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [namePage, setNamePage] = useState(page);
  const { SubMenu } = Menu;
  const rolesUser = profile?.extra_info?.roles;

  const SignOut = () => {
    localStorage.clear();
    History.push("/sign-in");
  };

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img onClick={()=>window.location.reload()} src={logo} alt="" style={{ width: "80%", cursor:'pointer'}} />
        {/* <span>Global Care</span> */}
      </div>
      {/* <Divider /> */}
      <hr className="gradient-line" />
      <Menu
        // defaultSelectedKeys={['2']}
        openKeys={namePage}
        theme="light"
        mode="inline"
        inlineCollapsed={true}
      >
        <Menu.Item onClick={() => setNamePage("1")} key={"1"}>
          <NavLink to="/quan-ly-tai-khoan">
            {/* <span
              className="icon"
              style={{
                background:
                  page === "dashboard" && namePage === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span> */}
            <span>
              <img src={Itaikhoan} style={{ width: "30px", marginRight: 5 }} />
            </span>
            <span className="label">Quản lý tài khoản</span>
          </NavLink>
        </Menu.Item>
        {/* <SubMenu
          key={"2"}
          onTitleClick={() => setNamePage("2")}
          icon={
            <span
              className="icon"
              style={{
                background: page === "2" ? color : "",
              }}
            >
              {tables}
            </span>
          }
          title={
            <span style={{ margin: 0 }} className="label">
              Đơn hàng
            </span>
          }
        >
          <Menu.Item>
            <NavLink to="/don-hang">
              <span
                className="icon"
                style={{
                  background: page === "tables" || page ==='chi-tiet' ? color : "",
                }}
              >
                <div className="iconItemSub"></div>
              </span>
              <span className="label label-menu-itenm-sub">
                Sức khoẻ vàng
              </span>
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/don-hang">
              <span
                className="icon"
                style={{
                  background: page === "tables" ? color : "",
                }}
              >
                <div className="iconItemSub"></div>
              </span>
              <span className="label label-menu-itenm-sub">
                Bảo hiểm vận chuyển
              </span>
            </NavLink>
          </Menu.Item>
        </SubMenu> */}
        {rolesUser?.includes("request upload") && (
          <Menu.Item onClick={() => setNamePage("3")} key="3">
            <NavLink to="/upload-chung-tu">
              <span>
                <img src={Iupload} style={{ width: "30px", marginRight: 5 }} />
              </span>
              <span className="label">Upload chứng từ</span>
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item onClick={() => setNamePage("6")} key="6">
          <NavLink to="/quan-ly-bao-cao">
            <span>
              <img src={Ibaocao} style={{ width: "30px", marginRight: 5 }} />
            </span>
            <span className="label">Quản lý báo cáo</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <Row>
            <Col
              onClick={() => SignOut()}
              style={{ marginLeft: "12%", marginTop: 10 }}
              xs={24}
              md={24}
              lg={0}
            >
              <span>
                <img
                  src={Idangxuat2}
                  style={{ width: "30px", marginRight: 5 }}
                />
              </span>
              <span style={{color:'#141414'}}>Đăng xuất</span>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          cursor: "pointer",
        }}
      >
        <Row>
          <Col
            style={{ marginLeft: 15 }}
            onClick={() => SignOut()}
            xs={0}
            md={0}
            lg={24}
          >
            <img src={Idangxuat} style={{ width: 18, marginRight: 5 }} />
            Đăng xuất
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Sidenav;
